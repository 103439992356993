import React from 'react';

type DelayProps = {
	children: React.ReactNode;
	seconds: number;
};

const Delay = ({children, seconds}: DelayProps) => {
	const [isReady, setIsReady] = React.useState(false);

	React.useEffect(() => {
		const timeout = setTimeout(() => {
			setIsReady(true);
		}, seconds * 1000);

		return () => clearTimeout(timeout);
	}, [seconds]);

	return isReady ? <>{children}</> : null;
};

export default Delay;
