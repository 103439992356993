import React, {useEffect, useCallback} from 'react';
import {Box, Button, Center, VStack, Text, chakra, Spinner, Progress} from '@chakra-ui/react';
import {Link, useNavigate} from 'react-router-dom';
import useMe from '../../../hooks/useMe';
import {Puddleglum} from '../../../puddleglum';
import GradientHeading from '../components/GradientHeading';
import Page from '../components/Page';

function Home() {
	const {me, isLoading} = useMe();
	const navigate = useNavigate();

	const routeToPortal = useCallback(
		(userRoles: Puddleglum.Models.UserRole[]) => {
			if (userRoles?.length === 1) {
				if (userRoles?.find((role) => role.name === 'Admin')) {
					window.location.href = '/admin';
				} else if (userRoles?.find((role) => role.name === 'Provider')) {
					window.location.href = '/provider';
				} else if (userRoles?.find((role) => role.name === 'Employer')) {
					window.location.href = '/employer';
				} else if (userRoles?.find((role) => role.name === 'Employee')) {
					window.location.href = '/patient';
				}
			} else navigate('/select-user-type');
		},
		[navigate],
	);

	useEffect(() => {
		if (!me || !me?.roles) {
			return;
		}

		routeToPortal(me.roles);
	}, [me, routeToPortal]);

	if (isLoading) {
		return <Progress size='xs' isIndeterminate />;
	}

	return (
		<Page>
			<Center w='100%' h='100vh'>
				<VStack>
					<Box
						bgColor='white'
						p={8}
						rounded='md'
						border='solid 1px'
						borderColor='gray.200'
						boxShadow='sm'
					>
						<Text size='sm' color='gray.500'>
							Welcome to
						</Text>
						<GradientHeading>Millennia Health</GradientHeading>
						<Center style={!isLoading ? {display: 'none'} : {}} w='100%' h='20vh'>
							<Spinner size='xl' />
						</Center>
						<VStack style={!isLoading && !me ? {} : {display: 'none'}} mt={4} w='full'>
							<Button as={Link} to='login' w='full' colorScheme='blue'>
								Log In
							</Button>
							<Button as={Link} to='signup' w='full' variant='outline'>
								Sign Up
							</Button>
						</VStack>
					</Box>
					<Box fontSize='.8em'>
						<chakra.a color='white' href='/terms' target='_blank'>
							Terms of Service
						</chakra.a>
						<chakra.a
							color='white'
							ml={4}
							href='https://www.calvient.com/privacy-policy'
							target='_blank'
						>
							Privacy Policy
						</chakra.a>
					</Box>
				</VStack>
			</Center>
		</Page>
	);
}

export default Home;
