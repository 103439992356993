import React from 'react';
import {HStack, Icon, VStack, Text} from '@chakra-ui/react';
import {MdCheck, MdErrorOutline} from 'react-icons/md';

interface PasswordHealthProps {
	password: string;
}

const PasswordHealth = ({password}: PasswordHealthProps) => {
	const hasUpperCase = /[A-Z]/.test(password);
	const hasLowerCase = /[a-z]/.test(password);
	const hasNumbers = /[0-9]/.test(password);
	const hasSpecial = /[^A-Za-z0-9]/.test(password);
	const isLong = password.length > 7;

	const successIcon = <Icon as={MdCheck} color='green.500' />;
	const failIcon = <Icon as={MdErrorOutline} color='red.500' />;

	return (
		<VStack alignItems='flex-start' spacing={0}>
			<HStack>
				{isLong ? successIcon : failIcon} <Text>At least 8 characters</Text>
			</HStack>
			<HStack>
				{hasUpperCase ? successIcon : failIcon} <Text>At least one uppercase letter</Text>
			</HStack>
			<HStack>
				{hasLowerCase ? successIcon : failIcon} <Text>At least one lowercase letter</Text>
			</HStack>
			<HStack>
				{hasNumbers ? successIcon : failIcon} <Text>At least one number</Text>
			</HStack>
			<HStack>
				{hasSpecial ? successIcon : failIcon} <Text>At least one special character</Text>
			</HStack>
		</VStack>
	);
};

export default PasswordHealth;
