import React, {FC, useState} from 'react';
import {
	Alert,
	AlertIcon,
	AlertTitle,
	Box,
	Button,
	Center,
	FormControl,
	FormLabel,
	HStack,
	Input,
	PinInput,
	PinInputField,
} from '@chakra-ui/react';
import {useLocation} from 'react-router-dom';
import {Puddleglum} from '../../../puddleglum';
import GradientHeading from '../components/GradientHeading';
import Page from '../components/Page';

const VerifyEnrollment: FC = () => {
	const {state} = useLocation();
	const [errorText, setErrorText] = useState<string>();
	const [isLoading, setIsLoading] = useState(false);
	const [verificationCode, setVerificationCode] = useState<string>();

	const verifyUser = async () => {
		try {
			setErrorText(undefined);
			setIsLoading(true);
			await Puddleglum.Controllers.Auth.RegisterController.verifyCode({
				email: state.email ?? '',
				verification_code: verificationCode ?? '',
			});
			window.location.href = '/signup/complete';
		} catch ({response}) {
			// @ts-ignore to be fixed in puddleglum, giving response is unknown
			setErrorText(response.data.message);
			setIsLoading(false);
		}
	};

	return (
		<Page>
			<Center w='100%' h='100vh'>
				<Box
					minW={{base: '90%', md: '50%', lg: '40%'}}
					bgColor='white'
					p={8}
					rounded='md'
					border='solid 1px'
					borderColor='gray.200'
					boxShadow='sm'
				>
					<GradientHeading>Verify Enrollment</GradientHeading>
					{errorText && (
						<Alert status='error'>
							<AlertIcon />
							<AlertTitle>{errorText}</AlertTitle>
						</Alert>
					)}
					<FormControl id='email'>
						<FormLabel fontSize='.8em' color='gray.500'>
							Email
						</FormLabel>
						<Input type='email' value={state.email} isDisabled />
					</FormControl>
					<FormControl id='verification-code' mt={4}>
						<FormLabel fontSize='.8em' color='gray.500'>
							Enter the verification code we just sent to your email:
						</FormLabel>
						<HStack>
							<PinInput otp onChange={setVerificationCode}>
								<PinInputField />
								<PinInputField />
								<PinInputField />
								<PinInputField />
								<PinInputField />
								<PinInputField />
							</PinInput>
						</HStack>
					</FormControl>
					<HStack justifyContent='space-between' py={4}>
						<Button
							type='submit'
							colorScheme='blue'
							onClick={verifyUser}
							isLoading={isLoading}
						>
							Submit
						</Button>
					</HStack>
				</Box>
			</Center>
		</Page>
	);
};

export default VerifyEnrollment;
