import React, {FC, useState} from 'react';
import {Box, Button, Center, chakra, Checkbox, FormControl} from '@chakra-ui/react';
import {Puddleglum} from '../../../puddleglum';
import GradientHeading from '../components/GradientHeading';
import Page from '../components/Page';

const AcceptTerms: FC = () => {
	const [acceptTerms, setAcceptTerms] = useState(false);
	const [consentToSms, setConsentToSms] = useState(false);
	const [isSaving, setIsSaving] = useState(false);

	const handleAcceptTerms = async () => {
		setIsSaving(true);
		await Puddleglum.Controllers.Auth.RegisterController.acceptTerms({
			sms_consent: consentToSms,
		});
		window.location.href = '/';
	};

	return (
		<Page overflow='auto'>
			<Center w='100%' p={{base: 'none', sm: 8}}>
				<Box
					pos={{base: 'fixed', sm: 'relative'}}
					top={0}
					bottom={0}
					overflowY='auto'
					w={{base: '100%', sm: '60%', lg: '50%'}}
					bgGradient='linear(to-b, white, gray.200)'
					rounded={{base: 'none', sm: 'xl'}}
					boxShadow='md'
					p={8}
				>
					<GradientHeading my={4}>Please review our terms of service.</GradientHeading>

					<FormControl my={8}>
						<Checkbox
							size='lg'
							isChecked={acceptTerms}
							onChange={(e) => setAcceptTerms(e.target.checked)}
						>
							I have read our{' '}
							<chakra.a
								href='/terms'
								target='_blank'
								textDecoration='underline'
								color='blue.500'
							>
								terms of service
							</chakra.a>{' '}
							and{' '}
							<chakra.a
								href='https://www.calvient.com/privacy-policy'
								target='_blank'
								textDecoration='underline'
								color='blue.500'
							>
								privacy policy
							</chakra.a>{' '}
							and agree to them.
						</Checkbox>
					</FormControl>

					<FormControl my={8}>
						<Checkbox
							size='lg'
							isChecked={consentToSms}
							onChange={(e) => setConsentToSms(e.target.checked)}
						>
							I consent to receive SMS messages from the Millennia Health App.
						</Checkbox>
					</FormControl>

					<Button
						w='full'
						colorScheme='blue'
						onClick={handleAcceptTerms}
						isLoading={isSaving}
						isDisabled={!acceptTerms}
					>
						Take Me to Millennia
					</Button>
				</Box>
			</Center>
		</Page>
	);
};
export default AcceptTerms;
