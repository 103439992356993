import React, {FC, useState} from 'react';
import {
	Alert,
	AlertIcon,
	AlertTitle,
	Box,
	Button,
	Center,
	FormControl,
	FormLabel,
	Text,
	Input,
	Spinner,
	Checkbox,
	HStack,
	PinInput,
	PinInputField,
	ListItem,
	SlideFade,
	UnorderedList,
} from '@chakra-ui/react';
import {Link} from 'react-router-dom';
import {Puddleglum} from '../../../puddleglum';
import GradientHeading from '../components/GradientHeading';
import Page from '../components/Page';
import Delay from 'components/Delay';

const LoginForm: FC = () => {
	const [currentStep, setCurrentStep] = useState<'login' | 'resetPassword' | 'verifyCode'>(
		'login',
	);
	const [email, setEmail] = useState<string>();
	const [password, setPassword] = useState<string>();
	const [rememberMe, setRememberMe] = useState<number>(0);
	const [userId, setUserId] = useState<number>();
	const [code, setCode] = useState<string>();
	const [errorText, setErrorText] = useState<string>();
	const [isLoading, setIsLoading] = useState(false);
	const [needsNewPassword, setNeedsNewPassword] = useState(false);
	const [newPassword, setNewPassword] = useState<string>();
	const [confirmNewPassword, setConfirmNewPassword] = useState<string>();
	const [passwordResetError, setPasswordResetError] = useState<string>();
	const [recipientsFor2fa, setRecipientsFor2fa] = useState<string[]>();

	const loginUser = async (isResending: boolean = false) => {
		try {
			setErrorText(undefined);
			setIsLoading(true);

			const deviceToken = localStorage.getItem('remember_device_token');
			const response = await Puddleglum.Controllers.Auth.LoginController.login({
				email: email ?? '',
				password: password ?? '',
				remember_device_token: deviceToken,
				is_resending: isResending,
			});

			if (response.data.message === 'Login successful') {
				window.location.href = '/';
				return;
			}

			setUserId(response.data.user.id);
			setRecipientsFor2fa(response.data.recipients);
			setCurrentStep('verifyCode');
			if (response.data.user.is_temporary_password) {
				setNeedsNewPassword(true);
			}
			setIsLoading(false);
		} catch (error: any) {
			setErrorText(`An error has occurred: ${error.response.data.message}`);
			setUserId(undefined);
			setIsLoading(false);
		}
	};

	const confirmCode = async () => {
		try {
			if (!userId || !code) {
				throw new Error('Oops! Something went wrong.');
			}

			setErrorText(undefined);
			setIsLoading(true);
			const reply = await Puddleglum.Controllers.Auth.LoginController.verifyLogin(userId, {
				code,
				remember: rememberMe,
			});

			// Store the remember token in local storage, to be used on subsequent logins
			if (reply.data.remember_device_token)
				localStorage.setItem('remember_device_token', reply.data.remember_device_token);

			if (needsNewPassword) {
				setCurrentStep('resetPassword');
			} else {
				window.location.href = '/';
				return;
			}

			setIsLoading(false);
		} catch (error: any) {
			setErrorText(error.response.data.message);
			setIsLoading(false);
		}
	};

	const resetPassword = async () => {
		if (newPassword !== confirmNewPassword) {
			setPasswordResetError('Passwords do not match');
			return;
		}
		try {
			setIsLoading(true);
			await Puddleglum.Controllers.UserController.changeTemporaryPassword({
				password: newPassword ?? '',
			});

			window.location.href = '/';
			return;
		} catch (error) {
			setPasswordResetError('An error occurred while resetting your password');
			setIsLoading(false);
		}
	};

	return (
		<Page>
			<Center w='100%' h='100vh'>
				<Box
					w={{base: '90%', md: '50%', lg: '40%'}}
					bgColor='white'
					p={8}
					rounded='md'
					border='solid 1px'
					borderColor='gray.200'
					boxShadow='sm'
				>
					<Center style={isLoading ? {} : {display: 'none'}} w='100%' h='20vh'>
						<Spinner size='xl' />
					</Center>
					<form
						style={isLoading ? {display: 'none'} : {}}
						onSubmit={(e) => {
							e.preventDefault();
						}}
					>
						{currentStep === 'login' && (
							<>
								<GradientHeading>Log In</GradientHeading>
								{errorText && (
									<Alert status='error'>
										<AlertIcon />
										<AlertTitle>{errorText}</AlertTitle>
									</Alert>
								)}

								<FormControl mt={2} id='email' isRequired>
									<FormLabel fontSize='.8em' color='gray.500'>
										Work Email Address
									</FormLabel>
									<Input
										key='email'
										name='email'
										type='email'
										value={email}
										onChange={(e) => setEmail(e.target.value)}
									/>
								</FormControl>

								<FormControl mt={2} id='password' isRequired>
									<FormLabel fontSize='.8em' color='gray.500'>
										Password
									</FormLabel>
									<Input
										key='password'
										name='password'
										type='password'
										value={password}
										onChange={(e) => setPassword(e.target.value)}
									/>
								</FormControl>

								<Link to='/forgot-password'>
									<Text color='teal.500' fontSize='xs' mt={2}>
										Forgot Password?
									</Text>
								</Link>

								<Button
									type='submit'
									mt={4}
									colorScheme='blue'
									w='full'
									onClick={() => loginUser(false)}
									isLoading={isLoading}
								>
									Login
								</Button>
								<Button as={Link} to='/' mt={2} variant='outline' w='full'>
									Back
								</Button>
							</>
						)}

						{currentStep === 'verifyCode' && (
							<>
								<FormControl id='code'>
									<FormLabel>
										{(recipientsFor2fa ?? []).length === 1 &&
											`Please enter the code sent to: ${recipientsFor2fa![0]}`}

										{(recipientsFor2fa ?? []).length > 1 && (
											<>
												<strong>
													Please enter the code we sent to the following:
												</strong>
												<UnorderedList>
													{(recipientsFor2fa ?? []).map((recipient) => (
														<ListItem key={recipient}>
															{recipient}
														</ListItem>
													))}
												</UnorderedList>
											</>
										)}
									</FormLabel>
									<HStack mt={4}>
										<PinInput otp onChange={setCode}>
											<PinInputField />
											<PinInputField />
											<PinInputField />
											<PinInputField />
											<PinInputField />
											<PinInputField />
										</PinInput>
									</HStack>
								</FormControl>

								<Checkbox
									mt={4}
									colorScheme='teal'
									value={rememberMe}
									onChange={(e) => setRememberMe(e.target.checked ? 1 : 0)}
								>
									Remember this device for 30 days
								</Checkbox>

								<Button
									type='submit'
									mt={8}
									colorScheme='blue'
									w='full'
									onClick={confirmCode}
									isLoading={isLoading}
								>
									Confirm Code
								</Button>
								<Delay seconds={5}>
									<SlideFade in>
										<Button
											mt={2}
											variant='ghost'
											colorScheme='blue'
											w='full'
											onClick={() => loginUser(true)}
											isLoading={isLoading}
										>
											Send Another Code
										</Button>
									</SlideFade>
								</Delay>
							</>
						)}

						{currentStep === 'resetPassword' && (
							<>
								<GradientHeading>Reset Password</GradientHeading>
								{passwordResetError && (
									<Alert status='error'>
										<AlertIcon />
										<AlertTitle>{passwordResetError}</AlertTitle>
									</Alert>
								)}
								<FormControl mt={2} id='new-password' isRequired>
									<FormLabel fontSize='.8em' color='gray.500'>
										New Password
									</FormLabel>
									<Input
										name='new-password'
										type='password'
										value={newPassword}
										onChange={(e) => setNewPassword(e.target.value)}
									/>
								</FormControl>

								<FormControl mt={2} id='confirm-new-password' isRequired>
									<FormLabel fontSize='.8em' color='gray.500'>
										Confirm New Password
									</FormLabel>
									<Input
										name='confirm-new-password'
										type='password'
										value={confirmNewPassword}
										onChange={(e) => setConfirmNewPassword(e.target.value)}
									/>
								</FormControl>

								<Button
									type='submit'
									mt={4}
									colorScheme='blue'
									w='full'
									isLoading={isLoading}
									onClick={resetPassword}
								>
									Reset Password
								</Button>
							</>
						)}
					</form>
				</Box>
			</Center>
		</Page>
	);
};

export default LoginForm;
