import React from 'react';
import {ChakraProvider} from '@chakra-ui/react';
import {QueryClient, QueryClientProvider} from '@tanstack/react-query';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import createAppWithSentry from '../../components/CreateAppWithSentry';
import makeTheme from '../../theme/makeTheme';
import EmployerPortalHomeScreen from '../employer/pages/EmployerPortalHomeScreen';
import PatientPortalHomeScreen from '../patient/pages/PatientPortalHomeScreen';
import ProviderPortalHomeScreen from '../provider/pages/ProviderPortalHomeScreen';
import AcceptTerms from './pages/AcceptTerms';
import CompleteSignup from './pages/CompleteSignup';
import ForgotPassword from './pages/ForgotPassword';
import Home from './pages/Home';
import LoginForm from './pages/LoginForm';
import RedirectPortal from './pages/RedirectPortal';
import SignupForm from './pages/SignupForm';
import VerifyEnrollment from './pages/VerifyEnrollment';

const queryClient = new QueryClient();
const globalStyles = {
	body: {
		bg: 'gray.100',
	},
};

function App() {
	return (
		<ChakraProvider theme={makeTheme(globalStyles)}>
			<QueryClientProvider client={queryClient}>
				<Router>
					<Routes>
						<Route path='/' element={<Home />} />
						<Route path='/signup' element={<SignupForm />} />
						<Route path='/signup/verify' element={<VerifyEnrollment />} />
						<Route path='/signup/complete' element={<CompleteSignup />} />
						<Route path='/signup/accept-terms' element={<AcceptTerms />} />
						<Route path='/login' element={<LoginForm />} />
						<Route path='/forgot-password' element={<ForgotPassword />} />
						<Route path='/employer' element={<EmployerPortalHomeScreen />} />
						<Route path='/patient' element={<PatientPortalHomeScreen />} />
						<Route path='/provider' element={<ProviderPortalHomeScreen />} />
						<Route path='/select-user-type' element={<RedirectPortal />} />
					</Routes>
				</Router>
			</QueryClientProvider>
		</ChakraProvider>
	);
}

createAppWithSentry('root', <App />);
