import React, {FC} from 'react';
import {Box, BoxProps, Icon, VStack} from '@chakra-ui/react';
import {IconType} from 'react-icons';

interface IconBoxButtonProps extends BoxProps {
	icon: IconType;
	label: string;
	onClick: () => void;
}

const IconBoxButton: FC<IconBoxButtonProps> = ({label, icon, onClick, ...rest}) => (
	<Box
		onClick={onClick}
		role='button'
		color='gray.500'
		border='solid 1px'
		borderColor='gray.200'
		borderRadius='md'
		p={{base: 4, md: 8}}
		w='full'
		_hover={{
			backgroundColor: 'gray.50',
			color: 'blue.500',
		}}
		{...rest}
	>
		<VStack>
			<Box>
				<Icon as={icon} boxSize={{base: 12, md: 16}} />
			</Box>
			<Box textAlign='center'>{label}</Box>
		</VStack>
	</Box>
);

export default IconBoxButton;
