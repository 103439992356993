import React, {FC, useState} from 'react';
import {
	Alert,
	AlertIcon,
	AlertTitle,
	Box,
	Button,
	Center,
	FormControl,
	FormLabel,
	HStack,
	Input,
	Text,
} from '@chakra-ui/react';
import {useNavigate} from 'react-router-dom';
import {Puddleglum} from '../../../puddleglum';
import GradientHeading from '../components/GradientHeading';
import Page from '../components/Page';

const SignupForm: FC = () => {
	const navigate = useNavigate();
	const [errorText, setErrorText] = useState<string>();
	const [isLoading, setIsLoading] = useState(false);
	const [email, setEmail] = useState<string>();

	const sendVerificationCode = async () => {
		try {
			setErrorText(undefined);
			setIsLoading(true);
			await Puddleglum.Controllers.Auth.RegisterController.lookupEmailAndSendVerification({
				email: email ?? '',
			});
			navigate('/signup/verify', {state: {email}});
		} catch ({response}) {
			// @ts-ignore to be fixed in puddleglum, giving response is unknown
			setErrorText(response.data.message);
		} finally {
			setIsLoading(false);
		}
	};
	return (
		<Page>
			<Center w='100%' h='100vh'>
				<Box
					maxW='600px'
					minW={{base: '90%', md: '50%', lg: '40%'}}
					bgColor='white'
					p={8}
					rounded='md'
					border='solid 1px'
					borderColor='gray.200'
					boxShadow='sm'
				>
					<GradientHeading>Verify Enrollment</GradientHeading>
					{errorText && (
						<Alert status='error' borderRadius='md' my={4}>
							<AlertIcon />
							<AlertTitle>{errorText}</AlertTitle>
						</Alert>
					)}
					<Text>
						In order to use Millennia Health, your employer must have enrolled you in
						the program. Please enter your work email address below to verify your
						enrollment.
					</Text>
					<FormControl id='email' my={2}>
						<FormLabel fontSize='.8em' color='gray.500'>
							Email
						</FormLabel>
						<Input
							type='email'
							value={email}
							onChange={(e) => setEmail(e.target.value)}
						/>
					</FormControl>
					<HStack justifyContent='space-between' py={4}>
						<Button type='reset' variant='outline' onClick={() => navigate('/')}>
							Cancel
						</Button>

						<Button
							type='submit'
							colorScheme='blue'
							isLoading={isLoading}
							onClick={sendVerificationCode}
						>
							Submit
						</Button>
					</HStack>
				</Box>
			</Center>
		</Page>
	);
};

export default SignupForm;
